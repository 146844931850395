import { Component, Input, OnInit, ChangeDetectorRef , Renderer2} from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ShareticketsService } from 'src/app/demo/service/sharetickets.service';
import { GenererVenteService } from 'src/app/demo/service/generer-vente.service';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FactureServicesService } from 'src/app/demo/service/facture-services.service';
import * as writtenNumber from 'written-number';
writtenNumber.defaults.lang = 'fr';


interface FactureItem {
  reference: string;
  designation: string;
  quantity: number;
  pricePerUnitHT: number;
  tva: number;
  priceTtc: number;
  total: number;
  remise?: number;
}
interface Variation {
  id: number;
  vente: number;
  quantity: number;
  remise: number | null;
  prix_produit: number;
  ht: number | null;
  htVente : number | null;
  tva: number | null;
  code_article_generique: string;
  designation: string | null
  product? : any 
}
@Component({
  selector: 'app-facture-ticket-pdf',
  templateUrl: './facture-ticket-pdf.component.html',
  styleUrls: ['./facture-ticket-pdf.component.scss']
})
export class FactureTicketPDFComponent implements OnInit {
  currentDate: Date;
  companyInfo: any;
  ClientInfo: any;
  variations: Variation[] = [];
  ventes: any[] = [];
  factureItems: FactureItem[] = [];
  quantiteTotal
  consult
  facture
  numeroFactures: string = '...';
  constructor(private factureService: FactureServicesService  , private messageService: MessageService, private venteService: ShareticketsService, private confirmationService: ConfirmationService, private GenerVente: GenererVenteService, private cdr: ChangeDetectorRef,private config : ConfigurationService , private renderer: Renderer2) {
  }
  public showContent;
  type
  base
  store
  etablissement
  sumoffodec
  timbre
  fodec
  sumOfTaxes
  sumOfHt
  baseHt
  netpaye
  venteIds: number[] = [];
  remises: number[] = [];
  factureDialog
  imprim
  TVA
  totalRemise = 0;
   numero: any;
   
    convertToDinarAndMillime(n: number): string {
    n = Number(n.toFixed(3));
    let [dinarPart, millimePart] = n.toString().split('.');
    if (millimePart){
    millimePart.length === 2 ? millimePart = millimePart + '0' : millimePart;
    millimePart.length === 1 ? millimePart = millimePart + '00' : '';     }
    const dinarInWords = writtenNumber(parseInt(dinarPart));
    const dinarString = `${dinarInWords} dinar${dinarPart === '1' ? '' : 's'}`;

    if (!millimePart) {
      return dinarString;
    }
  
    const millimeInWords = writtenNumber(parseInt(millimePart));
    const millimeString = `${millimeInWords} millime${millimePart === '1' ? '' : 's'}`;
  
    return `${dinarString} et ${millimeString}`;
  }

  async showDialog(type: boolean, client: any, store: any, etab: any, consult?: boolean, facture? , imprim ? , numero ? , date ? ) {
    imprim ?  this.imprim = imprim : 
    this.venteIds = [];
    this.variations = [];
    this.totalRemise = 0 ;
    this.base = 0 ;
    this.baseHt = 0 ;
    this.quantiteTotal = 0
    this.ventes = [];
    this.factureItems = [];
    this.sumOfTaxes = 0;
    this.sumoffodec = 0;
    this.quantiteTotal = 0;
    this.sumOfHt = 0;
    this.timbre = 0;
    this.netpaye = 0;
    numero ? this.numeroFactures = numero : this.numeroFactures = '' ;
      numero ? this.numero = numero : this.numero ;
    date ? this.currentDate = date :  this.currentDate;

    if (consult) {
      this.facture = facture
      console.log('facture ,' , facture)
      this.consult = true;
      this.factureDialog = true
      this.ngOnInit();
    } else {
      this.ClientInfo = client
      console.log('clientInfooooooooooooooooooooooooooo ,' , client)
      this.store = store.id
      this.etablissement = etab.id
      console.log('the store iss', this.store)
      console.log('the etabbb  iss', this.etablissement)
      this.type = type
      this.factureDialog = true
      this.ngOnInit();
    }
  }
  async ngOnInit() {
    if (this.consult) {
      const attributes = this.facture?.data?.attributes;
      this.companyInfo = {
        name: attributes.name,
        companyName: attributes.companyName,
        address: attributes.company_address ,
        registrationNumber: attributes.company_registrationNumber,
        rc: attributes.company_rc,
        phone: attributes.company_phone,
        fax: attributes.company_fax,
        email: attributes.company_email || null ,
        taxIdentification: attributes.company_taxIdentification,
        routeAddress: attributes.company_routeAddress,
        imageUrl: attributes.imageUrl,
     
      };
      this.ClientInfo = {
        name: attributes.clientcode || '',
        address: attributes.client_address || '',
        clientcode: attributes.clientcode || '',
        phone: attributes.client_phone || '',
        CTVA : attributes.matricule_fiscale,
      };
      this.numeroFactures = attributes.numero_facture
      this.currentDate = attributes.date_facture || '';
      this.quantiteTotal = attributes.quantiteTotal || 0;
      this.sumOfTaxes = attributes.sumOfTaxes || 0;
      this.sumoffodec = attributes.sumoffodec || null;
      this.sumOfHt = attributes.sumOfHt || 0;
      this.timbre = attributes.timbre || null;
      this.timbre ===  0? this.timbre = null :
      this.netpaye = attributes.netpaye || 0;   

      console.log(this.companyInfo)
      const prod = attributes.produit_factures 
      console.log('prod',prod)
      if (prod && Array.isArray(prod.data)) {
        prod.data.forEach((data) => {
          let item: FactureItem = {
            reference: data.attributes.reference,
            designation: data.attributes.designation,
            quantity: data.attributes.quantite,
            pricePerUnitHT: data.attributes.prix_uht,
            tva: data.attributes.tva,
            priceTtc: data.attributes.prix_ttc,
            total: data.attributes.totale_ht,
            remise: data.attributes.remise,
          };
          this.TVA = data.attributes.tva
          this.factureItems.push(item);
          console.log(this.factureItems);
        });
      } else {
        console.error('Invalid or missing data in attributes.produit_factures');
      }

      this.showContent = true;
      if(this.imprim
        ){
          this.ngAfterViewInit();
        }
        // this.showContent = false;
    } else {
      this.showContent = false;
         if(this.numero){
        await this.factureService.getFacturebynumero(this.numero).then(item => {
          const data = item.data[0].attributes; // Assuming the data you need is in the first item of the data array
          this.companyInfo = {
            name: data.name || "mic",
            companyName: data.companyName,
            address: data.company_address,
            registrationNumber: data.company_registrationNumber,
            rc: data.company_rc,
            phone: data.company_phone,
            fax: data.company_fax,
            email: data.company_email,
            taxIdentification : data.company_taxIdentification ,
            routeAddress: data.company_routeAddress ,
            imageUrl: data.company_imageUrl ,
          };
        });
      }else{
      await this.config.getConfig().then((config) => {
        this.companyInfo = {
          name: config.data.attributes.name,
          companyName: config.data.attributes.companyName,
          address: config.data.attributes.address,
          registrationNumber: config.data.attributes.registrationNumber,
          rc: config.data.attributes.rc,
          phone: config.data.attributes.phone,
          fax: config.data.attributes.fax,
          email: config.data.attributes.email,
          taxIdentification: config.data.attributes.taxIdentification,
          routeAddress: config.data.attributes.routeAddress,
          imageUrl: config.data.attributes.imageUrl
        };
      });
      }
      this.venteService.selectedVentes$.subscribe((ventes) => {
        this.ventes = ventes;
      });
      console.log('aaaaaa', this.ventes.length);
      if (this.type === true) {
        this.venteIds = this.ventes.map((vente) => vente.id);
        console.log('idddddssssssss', this.venteIds)
        await this.GenerVente.getVariations(this.venteIds).then(
          (response) => {
            console.log ('la reponse est = : ' , response)
            if (response && response.data) {
              this.variations = response.data.map((item) => {
               
                const attributes = item.attributes;
                this.TVA = attributes.tva
                return {
                  id: item.id,
                  vente: attributes.vente.data.id,
                  quantity: attributes.quantity,
                  remise: attributes.remise == null ? 0 : attributes.remise,
                  prix_produit: attributes.prix_produit,
                  ht: attributes.ht,
                  htVente : attributes.htVente ,
                  tva: attributes.tva,
                  code_article_generique: attributes.variation.data.attributes.code_article_generique,
                  designation: attributes?.variation?.data?.attributes?.product?.data?.attributes?.designation || '',
                  product : attributes?.variation?.data?.attributes?.product
                };
              });
            } else {
              console.error('Invalid variations response:', response);
            }
          },
          (error) => {
            console.error('Error fetching variations', error);
          }
        );
        const anyClient = this.ventes.find((item) => item);
        console.log(anyClient)
        console.log(this.store)
        console.log(this.etablissement)
        await this.getlist();
        console.log(this.ventes)
        this.sumOfTaxes = this.ventes.reduce((total, item) => {
  const roundedTaxes = Math.round((item.taxes || 0) * 1000) / 1000;
  return total + roundedTaxes;
}, 0);
console.log('totall taxes est ' , this.sumOfTaxes)
this.sumoffodec = this.ventes.reduce((total, item) => total + (item.total_fodec || 0), 0);
this.baseHt = this.ventes.reduce((total, item) => total + (item.ht || 0), 0);
this.sumoffodec = this.sumoffodec 
  ? Math.round(this.sumoffodec * 1000) / 1000 
  : null;   
  this.sumoffodec ===  0? this.sumoffodec = null :
       
        this.showContent = true;

        console.log("Variations:", this.variations);
        this.factureItems = this.generateFactureItems(this.variations);
        console.log(" facture:", this.factureItems);
        this.quantiteTotal = this.getTotalQuantity(this.factureItems);
        this.sumOfHt = Math.round(
  this.factureItems.reduce((total, item) => total + (item.total || 0), 0) * 1000
) / 1000;

       this.base = Math.round(
  (this.sumoffodec > 0 
    ? this.sumOfHt + this.sumoffodec 
    : this.sumOfHt
  ) * 1000
) / 1000;
console.log('sumOfHt',this.base)
        this.timbre = anyClient.timbre
        this.timbre ===  0 ? this.timbre = null : '';
        this.netpaye =  this.sumoffodec > 0 ?  this.sumOfHt + this.sumOfTaxes + this.timbre + this.sumoffodec  :  this.sumOfHt + this.sumOfTaxes + this.timbre 
        this.venteService.setSelectedVentes([]);
      } else {
        if (this.ventes.length > 0) {
          console.log('aaaaaa', this.ventes.length);
          this.showContent = false;
          await this.GenerVente.getVariations(this.venteIds).then(
            (response) => {
              if (response && response.data) {
                this.variations = response.data.map((item) => {
                  const attributes = item.attributes;
                  return {
                    id: item.id,
                    vente: attributes.vente.data.id,
                    quantity: attributes.quantity,
                    remise: attributes.remise == null ? 0 : attributes.remise,
                    prix_produit: attributes.prix_produit,
                    ht: attributes.ht,
                    htVente : attributes.htVente,
                    tva: attributes.tva,
                    code_article_generique: attributes.variation.data.attributes.code_article_generique,
                    designation: attributes?.variation?.data?.attributes?.product?.data?.attributes?.designation || ''
                  };
                });
              } else {
                console.error('Invalid variations response:', response);
              }
            },
            (error) => {
              console.error('Error fetching variations', error);
            }
          );
          const anyClient = this.ventes.find((item) => item);
          this.etablissement = anyClient.idetab
          console.log(anyClient)
          console.log(this.store)
          console.log(this.etablissement)
          await this.getlist();
          this.sumOfTaxes = this.ventes.reduce((total, item) => total + (item.taxes || 0), 0);
          this.sumoffodec = this.ventes.reduce((total, item) => total + (item.total_fodec || 0), 0);
          this.baseHt = this.ventes.reduce((total, item) => total + (item.ht || 0), 0);
          this.showContent = true;
          this.cdr.detectChanges();
          console.log("Variations:", this.variations);
          this.factureItems = this.generateFactureItems(this.variations);
          console.log(" facture:", this.factureItems);
          this.quantiteTotal = this.getTotalQuantity(this.factureItems);
          this.sumOfHt = this.factureItems.reduce((total, item) => total + (item.total || 0), 0);
          this.timbre = anyClient.timbre
          this.netpaye = this.sumOfHt + this.sumOfTaxes + this.timbre + this.sumoffodec
          this.showContent = true;
          this.venteService.setSelectedVentes([]);
        } else {
          this.showContent = true;
        }
        
      }
    }
  };
convertirEnLettres(montant: number): string {
    const unites = ['', '', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize'];
    const dizaines = ['', 'dix', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'];
    
    // Fonction pour convertir chaque bloc de trois ou quatre chiffres
    function convertirBlocTroisChiffres(bloc: number): string {
      let millier = Math.floor(bloc / 1000);
      const reste = bloc % 1000;
    
      let resultat = '';
      let digits: number[] = [];
    
      if (millier > 100) {
        
        while (millier > 0) {
          const digit = millier % 10;
          digits.unshift(digit);
          millier = Math.floor(millier / 10);
      }
      let verif = false ;
      if (digits[1] === 7 || digits[1] === 9 ) {
        digits[1] = digits[1] - 1;
        digits[2] = digits[2] + 10;
        verif = true;
      }
      resultat += unites[digits[0]] + ' cent ';
      resultat += dizaines[digits[1]];
      if (digits[1] > 0) {
          resultat += '-' + unites[digits[2]];
      }
      resultat += ' mille ';
    } else if (millier > 16) {
        while (millier > 0) {
            const digit = millier % 10;
            digits.unshift(digit);
            millier = Math.floor(millier / 10);
        }
        let verif = false ;
        if (digits[0] === 7 || digits[0] === 9 ) {
          digits[0] = digits[0] - 1;
          digits[1] = digits[1] + 10;
          verif = true;
        }
        resultat += dizaines[digits[0]];
        if (digits[1] > 0) {
            resultat += '-' + unites[digits[1]];
        }
        resultat += ' mille ';
    } else if (millier > 0) {
        resultat += unites[millier] + ' mille';
    }

    if (reste > 0) {
        if (millier > 0) {
            resultat += ' ';
        }

    
        const centaines = Math.floor(reste / 100);
        const resteCentaines = reste % 100;
    
        if (centaines > 0) {
          if (centaines === 1 && resteCentaines === 0) {
            resultat += 'cent';
          } else {
            resultat += unites[centaines] + ' cent';
          }
        }
    
        if (resteCentaines > 0) {
          if (centaines > 0) {
            resultat += ' ';
          }
        
          if (resteCentaines < 10) {
            resultat += unites[resteCentaines];
          } else if (resteCentaines < 17) {
            resultat += 'et ' + unites[resteCentaines];
          } else {
            let unite = resteCentaines % 10;
            let dizaine = Math.floor(resteCentaines / 10);
            if (unite > 0) {
              if (dizaine === 1) {
                resultat += 'dix-' + unites[unite];
              } else {
                let diz = false ;
                if( dizaine === 7 || dizaine === 9) {
                  dizaine = dizaine - 1  ;
                  diz = true ;
                }
                 else {
                dizaine = dizaine;
                }
                
                resultat += dizaines[dizaine]; // Adjust the index to get the correct dizaine
                if (unite !== 1) {
                  diz === true ? unite += 10 : unite = unite ;
                  resultat += '-' + unites[unite];
                }
              }
            } else {
              resultat += dizaines[dizaine - 1]; // Adjust the index to get the correct dizaine
            }
          }
        }      }
    
      resultat = resultat.charAt(0).toUpperCase() + resultat.slice(1);
      return resultat.trim();
    }
  
    const partieEntiere = Math.floor(montant);
    let partieDecimale = Math.round((montant % 1) * 1000); // Convertir la partie décimale en entier
  
    const partieEntiereEnLettres = convertirBlocTroisChiffres(partieEntiere);
    let resultatFinal = partieEntiereEnLettres;
  
    if (partieDecimale > 0) {
      resultatFinal += ' dinar(s)';
  
      if (partieDecimale === 1) {
        resultatFinal += ' et un millime(s)';
      } else {
        if ( partieDecimale <10 )
        partieDecimale = partieDecimale *100 ;
      else if (  partieDecimale < 100 ) {
        partieDecimale = partieDecimale * 10 ;
      }
        resultatFinal += ' et ' + convertirBlocTroisChiffres(partieDecimale) + ' millime(s)';
      }
    } else {
      resultatFinal += ' dinar(s)';
    }
  
    return resultatFinal.trim();
    
}  

confirmationDialogId = 'yourConfirmationDialog';
  getlist(): void {
    const uniqueRemises = new Set(this.variations.map((variation) => variation.remise));
    this.remises = Array.from(uniqueRemises);
    console.log("Vente remises:", this.remises);
  }
  showConfirmation() {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir continuer?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: async() => {
        if (this.currentDate) {
          console.log('Accepté');
         await this.postfacture();
          this.ngAfterViewInit();
          this.confirmationService.close();
          this.factureDialog = false
        } else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Sélectionnez la date' });
          this.confirmationService.close();
        }
      },
      reject: () => {
        console.log('Rejeté');
        this.confirmationService.close();
      },
    });
  }
generateFactureItems(variations: Variation[]): FactureItem[] {
    const factureItems: FactureItem[] = [];
    let pourcentage = 0;
    variations.forEach((variation) => {
      let prixuht = 0;
      const existingItem = factureItems.find(
        (item) =>  item.remise === variation.remise && item.pricePerUnitHT === variation.htVente && variation.code_article_generique === item.reference 
      );
      const existingItem2 = this.ventes.find(
        (item) => item.id === variation.vente
      );
      pourcentage = existingItem2.pourcentage_remise;
      console.log('pourcentage ', pourcentage)
      if (pourcentage > 0) {
        prixuht = variation.ht - ((variation.ht * pourcentage) / 100);
      }
      if (existingItem) {
        existingItem.quantity += variation.quantity;
      } else {
        console.log('the product is ', variation.product)
        factureItems.push({
          reference: variation.code_article_generique,
          designation: variation.product.data.attributes.sous_famille.data.attributes.name,
          quantity: variation.quantity,
          remise: variation.remise || 0 ,
          pricePerUnitHT: prixuht > 0 ? prixuht : variation.htVente || 0, 
          tva: variation.tva || 0, 
          priceTtc: variation.prix_produit,
          total: 0, 
        });
      }
    });

    factureItems.forEach((item) => {
      const remiseAmount = item.remise > 0 ? item.pricePerUnitHT * item.quantity * (item.remise / 100) : 0 ;
      this.totalRemise += remiseAmount;
  });


    factureItems.forEach((item) => {
      // item.priceTtc = item.pricePerUnitHT * (1 + item.tva / 100);
      item.total = this.calculateTotal(item.quantity, item.pricePerUnitHT, item.tva);
    });
    return factureItems;
  }
  
  getTotalQuantity(factureItems: FactureItem[]): number {
    return factureItems.reduce((total, item) => total + item.quantity, 0);
  }
  calculateTotal(quantity: number, pricePerUnitHT: number, tva: number): number {
    const totalHT = quantity * pricePerUnitHT;
    const totalTTC = totalHT * (1 + tva / 100);
    return totalHT;
  }
  //------------------------------------------------ Post API and genereate pdf  ------------------------------------------------//
  async postfacture() {
    console.log(this.venteIds)
    console.log(this.currentDate)
   this.currentDate.setDate(this.currentDate.getDate() + 1) 
   console.log(this.currentDate)
    const factureJson = {
      "facture": {
        "date_facture": this.currentDate.toISOString().split('T')[0],
        "imageUrl" : this.companyInfo.imageUrl,
        "client": this.ClientInfo.id,
        "ventes": this.venteIds,
        "etablissement": this.etablissement,
        "store": this.store,
        "name": this.ClientInfo.name,
        "companyName": this.companyInfo.companyName,
        "company_address": this.companyInfo.address,
        "company_registrationNumber": this.companyInfo.registrationNumber,
        "client_name": this.companyInfo.client,
        "company_rc": this.companyInfo.rc,
        "company_phone": this.companyInfo.phone,
        "company_fax": this.companyInfo.fax,
        "company_email": this.companyInfo.email,
        "company_taxIdentification": this.companyInfo.taxIdentification,
        "company_routeAddress": this.companyInfo.routeAddress,
        "clientcode": this.ClientInfo.name,
        "client_address": this.ClientInfo.address,
        "client_phone": this.ClientInfo.phone,
        "quantiteTotal": this.quantiteTotal,
        "sumoffodec": this.sumoffodec,
        "timbre": this.timbre,
        "fodec": this.fodec ? this.fodec : 0,
        "sumOfTaxes": this.sumOfTaxes,
        "sumOfHt": this.sumOfHt,
        "netpaye": this.netpaye,
        "status_facture": this.type ? 2 : 1,
        "matricule_fiscale" : this.ClientInfo.CTVA
      },
      "produitsFacture": this.factureItems.map((item) => {
        return {
          "reference": item.reference,
          "designation": item.designation,
          "quantite": item.quantity,
          "prix_uht": item.pricePerUnitHT,
          "tva": item.tva,
          "prix_ttc": item.priceTtc,
          "totale_ht": item.total,
        };
      }),
    };
    console.log('data', factureJson)
    if (this.currentDate) {
      
      await this.GenerVente.createFacture(factureJson).then((data)=>{
         this.numeroFactures = data.facture.numero_facture
      });
      console.log('le numero est ' , this.numeroFactures)
    } else {
    }
  }
  ngAfterViewInit(): void {
    const content = document.getElementById('FactureContent');
    const imgElement = content.querySelector('img');
    const imageUrl = this.companyInfo.imageUrl;
  
    this.loadImage(imageUrl)
      .then(() => {
        this.generateInvoicePDF();
      })
      .catch(error => {
        console.error('Error loading image:', error);
      });
  
    this.renderer.setAttribute(imgElement, 'src', imageUrl);
  }
  
  loadImage(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject(new Error('Image load error'));
      img.src = url;
    });
  }
  useCORS: true
  generateInvoicePDF(): void {
    const content = document.getElementById('FactureContent');
    const options = {
      margin: 10,
      filename: 'Facture.pdf',
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2,useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    const imgElement = content.querySelector('img');
   html2pdf().set(options).from(content).save();
  
  }
}
