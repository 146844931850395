<div class="grid">
  <div class="col-12">
    <div class="card">

      <!--------------------Consultation Tickets PAGE---------------------->

      <h5>Consultation Facture</h5>
      <div class="container">

        <!------------------------------------------  Filtres et buttons  -------------------------------------------------------->

        <p-multiSelect [(ngModel)]="selectedEtablissement" [options]="Etablissements"
          (onChange)="onEtablissementChange($event)" placeholder="Etablissements" optionLabel="name" [filter]="true"
          [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
        </p-multiSelect>
        <div *ngIf="selectedEtablissement && selectedEtablissement.length > 0">
          <p-multiSelect [(ngModel)]="selectedStore" [options]="Depots" (onChange)="onStoreChange($event)"
            placeholder="Depot" optionLabel="name" [filter]="true"
            [style]="{ 'margin-right': '10px' , 'max-width' : '150px','min-width' : '150px'}">
          </p-multiSelect>
        </div>
        <p-multiSelect [(ngModel)]="selectedClient" [options]="Clients" (onChange)="onClientChange($event)"
          placeholder="Client" optionLabel="name" [filter]="true"
          [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
        </p-multiSelect>
        <p-multiSelect [(ngModel)]="selectedArticle" [options]="articles" (onChange)="onArticleChange($event)"
          placeholder="Article" optionLabel="code_article_generique" [filter]="true"
          [style]="{ 'margin-right': '10px' , 'max-width' : '150px','min-width' : '150px'}">
        </p-multiSelect>

        <p-calendar [touchUI]="true" [(ngModel)]="datevente" selectionMode="range" placeholder="Date"
          [readonlyInput]="true"></p-calendar>


        <button pButton pRipple type="button" icon="fa fa-filter bi bi-filter" label="Filtrer"
          class="p-button-success mr-0.5 mb-" style="margin-right: 10px;"
          [iconPos]="isPageMinimized() ? 'right' : 'left'" (click)="Filters()">
        </button>

        <button pButton pRipple type="button" icon="bi bi-arrow-clockwise" label="Refresher"
          class="btn btn-primary mr-0.5 mb-"
          style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
          [iconPos]="isPageMinimized() ? 'right' : 'left'" (click)="resetFilters()">
        </button>
      </div>

      <!------------------------------------------------------------------------------------------------------------------------>
      <!-------------------------------------------------------------- Table --------------------------------------------------->

      <p-table [loading]="loading" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalRecords"
        [value]="filteredVentes" responsiveLayout="stack" scrollHeight="flex" [paginator]="false" [rowHover]="true"
        [rows]="5" sortMode="single" [(selection)]="selectedVentes" dataKey="id" (onPage)="onPageChange($event)"
        (onLazyLoad)="onLazyLoad($event)" responsive="false" pDraggableRows>
        <ng-template pTemplate="caption">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            [selectedItemsLabel]="'{0} colonnes sélectionnées'" [style]="{ 'min-width': '200px' }"
            placeholder="Choisir les colonnes"></p-multiSelect>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of selectedColumns" pSortableColumn="{{ col.field }}">
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th style="width: 10em">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vente let-rowIndex="rowIndex">
          <tr pDraggableRow (onDragEnd)="onRowDragEnd($event)" pDraggable="vente">
            <td *ngFor="let col of selectedColumns">
              <ng-container *ngIf="col.field === 'status_facture' || col.field === 'status_vente' ; else normalColumn">
                <p-tag *ngIf="col.field === 'status_facture'" [severity]="vente[col.field] ? 'success' : 'danger'">
                  {{ vente[col.field] ? 'Facturé' : 'Non Facturé' }}
                </p-tag>
                <p-tag *ngIf="col.field === 'status_vente'"
                  [severity]="vente[col.field] === 'valide' ? 'success' : 'danger'">
                  {{ vente[col.field] }}
                </p-tag>
              </ng-container>
        
              <ng-template #normalColumn>
                <!-- If 'Total HT' or 'Total TTC', apply the roundToThreeDecimals pipe -->
                <ng-container *ngIf="col.field === 'ht' || col.field === 'ttc'; else otherColumn">
                  {{ vente[col.field] | roundToThreeDecimals }}
                </ng-container>
                <ng-template #otherColumn>
                  <!-- If not 'status_facture' or 'Total HT' or 'Total TTC', display as plain text -->
                  {{ vente[col.field] }}
                </ng-template>
              </ng-template>
            </td>
        
            <td>
              <button type="button" pButton pRipple icon="pi pi-eye" (click)="onCellClick(rowIndex , false)"
                style=" margin-right: 4px ;"></button>
              <button  style=" margin-right: 4px ;"  type="button" pButton pRipple icon="bi bi-pen" (click)="onCellClick(rowIndex , true)"></button>
              <button type="button" pButton pRipple icon="bi bi-receipt" (click)="imprimFacture(rowIndex )"></button>
            </td>
          </tr>
        </ng-template>
        
        <ng-template pTemplate="footer">
          <tr>
            <td *ngIf="TotalNet || TotalNet > 0">
              <p> le total des Prix Net est : {{TotalNet | roundToThreeDecimals}} TND</p>
            </td>
             <td *ngIf="TotalNet || TotalNet > 0">
              <p> le total des articles est : {{totalQTE }}</p>
            </td>
          </tr>
        </ng-template>

      </p-table>




      <p-paginator [totalRecords]="totalRecords" [rows]="size" [rowsPerPageOptions]="[5, 10, 25, 50]" [pageLinkSize]="5"
        (onPageChange)="onPageChangePaginator($event)" [alwaysShow]="true"></p-paginator>


      <div class="card" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="flex-grow: 1;">
        </div>
      </div>
      <div class="card flex justify-content-center"></div>
      <!------------------------------------------------------------------------------------------------------------------------>
    </div>
  </div>
  <p-toast></p-toast>
<app-details-ticket (refresh)="getfilterVentes()"></app-details-ticket>
  <app-facture-ticket-pdf></app-facture-ticket-pdf>
  