import { Component, OnInit, ViewChild } from "@angular/core";
import { AddVenteDialogComponent } from "../add-vente-dialog/add-vente-dialog.component";
import { VenteService } from "src/app/demo/service/vente.service";
import { MessageService } from "primeng/api";
// import { ParseSourceSpan } from 'node_modules-/codelyzer/angular/styles/parseUtil';
import { ConfigurationService } from "src/app/demo/service/configuration.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

interface vente {
  // codeVariation: string;
  id: number;
  ref: string;
  designation: string;
  qte: number;
  prixHT: number;
  prixTTC: number;
  article;
  prixHTR? : number ;
  remise: number;
  montantHT;
  montantTTC;
  variationsVente;
  expanded: boolean;
}
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: "app-ventes",
  templateUrl: "./ventes.component.html",
  styleUrls: ["./ventes.component.scss"],
})
export class VentesComponent implements OnInit {
  @ViewChild(AddVenteDialogComponent) addVenteDialog: AddVenteDialogComponent;
  @ViewChild(ConfirmationDialogComponent)
  ConfirmDialog: ConfirmationDialogComponent;
  showFodec: boolean = false;
  expandedRows: number[] = [];
  isExpanded: boolean[] = [];
  confirm;
  ventes: vente[] = [];
  Etablissements;
  Depots;
  Clients;
  date: Date;
  selectedEtablissement;
  selectedStore;
  slectedClient;
  CodeFourniss;
  filtredClient;
  filtredStore;
  filtredEtablissement;
  VenteProduct = [];
  VentesVariations = [];
  tva = 19;
  fodec: number = 0;
  timbre: number = 0;
  remisePercentage: number = 0.0;
  total: number = 0;
htAmount: number = 
  this.remisePercentage === 0.0 
    ? Math.round(this.total * 1000) / 1000 
    : Math.round((Math.round(this.total * 1000) / 1000 * Math.round((100 - this.remisePercentage) * 1000) / 1000) / 100 * 1000) / 1000;

remiseAmount: number = Math.round((Math.round(this.total * 1000) / 1000 * Math.round(this.remisePercentage * 1000) / 1000) / 100 * 1000) / 1000;

totalFodec: number = Math.round((Math.round(this.htAmount * 1000) / 1000 * Math.round(this.fodec * 1000) / 1000) / 100 * 1000) / 1000;

taxesAmount: number = Math.round(((Math.round(this.htAmount * 1000) / 1000 + Math.round(this.totalFodec * 1000) / 1000) * Math.round(this.tva * 1000) / 1000) / 100 * 1000) / 1000;

timbreAmount: number = Math.round(this.timbre * 1000) / 1000;

ttcAmount: number = Math.round((Math.round(this.htAmount * 1000) / 1000 + Math.round(this.taxesAmount * 1000) / 1000) * 1000) / 1000;

netToPayAmount: number = Math.round((Math.round(this.ttcAmount * 1000) / 1000 + Math.round(this.timbreAmount * 1000) / 1000 + Math.round(this.totalFodec * 1000) / 1000) * 1000) / 1000;

  constructor(
    private venteService: VenteService,
    private messageService: MessageService,
    private configuration: ConfigurationService
  ) {
    this.expandedRows = [];
    this.date = new Date();
    console.log(this.date);
  }
  async getConfiguration() {
    await this.configuration.getConfig().then((data) => {
      this.tva = data.data.attributes.tva;
      console.log("tva", this.tva);
      const fodec = data.data.attributes?.fodec;
      console.log("fodec", fodec);
      const timbre = data.data.attributes?.timbre;
      console.log("timbre", timbre);
      if (fodec) {
        this.showFodec = true;
        this.fodec = fodec;
      }
      if (timbre) {
        this.timbre = parseFloat(timbre.toFixed(3));
      }
    });
  }
  async ngOnInit() {
    await this.getEtablissement();
    await this.getClients();
    await this.getConfiguration();
      this.timbreAmount = this.timbre;
      this.netToPayAmount = this.ttcAmount + this.timbreAmount;
   
  }
  onInput(event: any) {
    // Update the timbreAmount on input
    this.timbreAmount = parseFloat(event.target.value);
  }

  onChange(event: any) {
    // Round the timbreAmount to the desired decimal places after the change event
    this.timbreAmount = parseFloat(this.timbreAmount.toFixed(3));
  }
  validateTimbre(event) {
    /* const inputValue = event.target.value;
   const dotIndex = inputValue.indexOf('.');
     const commaIndex = inputValue.indexOf(',');
 
     if (dotIndex !== -1 || commaIndex !== -1) {
       const decimalSeparatorIndex = dotIndex !== -1 ? dotIndex : commaIndex;
     const decimalPart = inputValue.substring(decimalSeparatorIndex + 1);
 
     if (decimalPart.length >= 3) {
       event.preventDefault();
     }
       }*/

    event.preventDefault();
  }

  validateRemise(event) {
    // console.log(event)
    const inputValue = event.target.value;
    console.log(inputValue);

    // Ensure the input is a non-negative number between 0 and 100
    const pattern = /^(100|[1-9]?\d|0)$/;
    if (pattern.test(inputValue)) {
      this.remisePercentage = parseInt(inputValue, 10);
    } else {
      this.remisePercentage = 0;
    }
  }

  validateInput() {
    if (this.remisePercentage < 0) {
      this.remisePercentage = 0;
    } else if (this.remisePercentage > 100) {
      this.remisePercentage = 100;
    }
  }
  updateValues() {
      
 // Round every intermediate calculation to 3 decimal places
this.htAmount = 
  this.remisePercentage === 0.0 
    ? Math.round(this.total * 1000) / 1000 
    : Math.round((Math.round(this.total * 1000) / 1000 * Math.round((100 - this.remisePercentage) * 1000) / 1000) / 100) * 1000 / 1000;

this.remiseAmount = Math.round((Math.round(this.total * 1000) / 1000 * Math.round(this.remisePercentage * 1000) / 1000) / 100 * 1000) / 1000;

this.totalFodec = Math.round((Math.round(this.htAmount * 1000) / 1000 * Math.round(this.fodec * 1000) / 1000) / 100 * 1000) / 1000;

this.taxesAmount = Math.round(((Math.round(this.htAmount * 1000) / 1000 + Math.round(this.totalFodec * 1000) / 1000) * Math.round(this.tva * 1000) / 1000) / 100 * 1000) / 1000;

this.timbreAmount = Math.round(this.timbreAmount * 1000) / 1000;

this.ttcAmount = Math.round((Math.round(this.htAmount * 1000) / 1000 + Math.round(this.taxesAmount * 1000) / 1000) * 1000) / 1000;

this.netToPayAmount = Math.round((Math.round(this.ttcAmount * 1000) / 1000 + Math.round(this.timbreAmount * 1000) / 1000 + Math.round(this.totalFodec * 1000) / 1000) * 1000) / 1000;
console.log('med',this.remiseAmount)
  }

  cols = [
    { field: "codeVariation", header: "Code Variation" },
    { field: "ref", header: "Référence" },
    { field: "designation", header: "Designation" },
    { field: "qte", header: "Quantité" },
    { field: "prixht", header: "Prix HT" },
    { field: "prixttc", header: "Prix TTC" },
    { field: "remise", header: "Remise" },
    { field: "Total_HT", header: "Total HT" },
    { field: "Total_TTC", header: "Total TTC" },
    { field: "action", header: "Action" },
  ];
  filterClient(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Clients as any[]).length; i++) {
      let country = (this.Clients as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filtredClient = filtered;
  }
  filterEtablissement(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
      let country = (this.Etablissements as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filtredEtablissement = filtered;
  }
  filterStore(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Depots as any[])?.length; i++) {
      let country = (this.Depots as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filtredStore = filtered;
  }
  async getEtablissement() {
    await this.venteService.getEtablissements().then((res) => {
      console.log(res);
      this.Etablissements = res.data.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        stores: item.attributes.stores.data.map((store) => ({
          id: store.id,
          name: store.attributes.name,
        })),
      }));
    });
    console.log(this.Etablissements);
    this.selectedEtablissement = this.Etablissements[0];
    this.Depots = this.Etablissements[0].stores;
    this.selectedStore = this.Etablissements[0].stores[0];
  }
  async getSelectedEatb() {
    this.Depots = [];
    this.selectedStore = "";
    console.log("selected Etablissement", this.selectedEtablissement);
    this.Depots = this.selectedEtablissement.stores;
    this.selectedStore = this.selectedEtablissement.stores[0];
  }
  getSelectedDepot() {
    console.log("selected depot", this.selectedStore);
  }
  getSelectedClient() {
    console.log("selected client", this.slectedClient);
  }
  getClients() {
    this.venteService.getClients().then((res) => {
      // console.log('client',res)
      this.Clients = res.data.map((item) => ({
        id: item.id,
        name: item.attributes.name,
      }));
      console.log("clients", this.Clients);
    });
  }

  async openDialog() {
    const DetailsVenteParent = {
      etablissement: this.selectedEtablissement,
      store: this.selectedStore,
    };
    console.log(DetailsVenteParent);
    this.addVenteDialog.showDialog(DetailsVenteParent);
  }

  toggleRow(item, rowIndex) {
    console.log(item);
    item.expanded = !item.expanded;
    console.log(item.expanded, rowIndex);
    if (item.expanded) {
      this.expandedRows.push(rowIndex);
      console.log(this.expandedRows);
    } else {
      const indexToRemove = this.expandedRows.indexOf(rowIndex);
      if (indexToRemove !== -1) {
        this.expandedRows.splice(indexToRemove, 1);
        console.log(this.expandedRows);
      }
    }
  }

  receiveTicket(data) {
    console.log("ticket from child", data);
    const foundTicket = this.ventes.find(
      (item) => item.article.id === data.article.id
    );

    if (foundTicket) {
      const foundObjectIndex = this.ventes.findIndex(
        (item) => item.article.id === data.article.id
      );
      const remiseOfFoundAchat = this.ventes[foundObjectIndex].remise;

      console.log("Matching ticket found:", this.ventes[foundObjectIndex]);
      const TicketTomodify = this.ventes[foundObjectIndex];
      const variationsTomodify = this.ventes[foundObjectIndex].variationsVente;
      const oldMontantHt = TicketTomodify.montantHT;
      console.log("old montant ht", oldMontantHt);

      //same achat same remise

      if (data.remise == remiseOfFoundAchat) {
        for (let variationdata of data.VenteVariations) {
          const foundVariation = variationsTomodify.find(
            (item) => item.ID === variationdata.ID
          );
          console.log("Matching variation found:", foundVariation);

          if (foundVariation) {
            const foundVariationIndex = variationsTomodify.findIndex(
              (item) => item.ID === variationdata.ID
            );
            console.log(
              "index of Matching variation found:",
              variationsTomodify[foundVariationIndex]
            );
            variationsTomodify[foundVariationIndex].QTE += variationdata.QTE;
            console.log(
              "modifyed variation :",
              variationsTomodify[foundVariationIndex]
            );
            console.log("modifyed ticket :", this.ventes[foundObjectIndex]);
            TicketTomodify.qte += variationdata.QTE;
            TicketTomodify.montantHT =
              Math.round(TicketTomodify.qte * TicketTomodify.prixHT * 100) /
              100;
            TicketTomodify.montantTTC =
              Math.round(TicketTomodify.qte * TicketTomodify.prixTTC * 100) /
              100;
            this.total -= oldMontantHt;
            this.total += TicketTomodify.montantHT;
            console.log("new total", this.total);
            this.htAmount = this.total;
            console.log(this.ventes);
          } else {
            variationsTomodify.push(variationdata);
            console.log(
              "add new variation to existing ticket:",
              this.ventes[foundObjectIndex]
            );
            TicketTomodify.qte += variationdata.QTE;
            TicketTomodify.montantHT =
              Math.round(TicketTomodify.qte * TicketTomodify.prixHT * 100) /
              100;
            TicketTomodify.montantTTC =
              Math.round(TicketTomodify.qte * TicketTomodify.prixTTC * 100) /
              100;
            this.total -= oldMontantHt;
            this.total += TicketTomodify.montantHT;
            console.log("new total", this.total);
            this.htAmount = this.total;

            console.log(this.ventes);
          }
        }
      }
      //same achat diff remise
      else {
        console.log("No matching ticket found");
        let sommeQte = 0;

        for (let variat of data.VenteVariations) {
          const quantite = parseInt(variat.QTE, 10);
          if (!isNaN(quantite)) {
            sommeQte += quantite;
          }
        }
        
        const vente: vente = {
          id: this.ventes!.length ? this.ventes.length : 0,
          article: data.article,
          ref: data.ref,
          designation: data.designation,
          qte: sommeQte,
          prixHT: data.prixHT,
          prixHTR : data.article.prix_vente_ht,
          prixTTC: data.prixTTC,
          remise: data.remise,
          montantTTC: Math.round(sommeQte * data.prixTTC * 100) / 100,
          montantHT:  Math.round((Math.round(data.article.prix_vente_ht * sommeQte * 100) / 100 - Math.round(data.article.prix_vente_ht * sommeQte * data.remise / 100 * 100) / 100) * 100) / 100,
          variationsVente: data.VenteVariations,
          expanded: false,
        };
        console.log(data.article.prix_vente_ht * sommeQte * 100,data.article.prix_vente_ht * sommeQte * data.remise / 100);
        this.ventes.push(vente);
        this.total += vente.montantHT;

        console.log(this.total);
        console.log(this.ventes);
      }
    } else {
      console.log("No matching ticket found");
      let sommeQte = 0;

      for (let variat of data.VenteVariations) {
        const quantite = parseInt(variat.QTE, 10);
        if (!isNaN(quantite)) {
          sommeQte += quantite;
        }
      }
      
      console.log('medddd',  Math.round(((data.article.prix_vente_ht*sommeQte)-(data.article.prix_vente_ht*sommeQte * data.remise / 100))* 100) / 100  )
      console.log('meddata',data,sommeQte)
      const vente: vente = {
        id: this.ventes!.length ? this.ventes.length : 0,
        article: data.article,
        ref: data.ref,
        designation: data.designation,
        qte: sommeQte,
        prixHT: data.prixHT,
        prixHTR : data.article.prix_vente_ht,
        prixTTC: data.prixTTC,
        remise: data.remise,
        montantTTC: Math.round(sommeQte * data.prixTTC * 100) / 100, 
        montantHT: Math.round((Math.round(data.article.prix_vente_ht * sommeQte * 100) / 100 - Math.round(data.article.prix_vente_ht * sommeQte * data.remise / 100 * 100) / 100) * 100) / 100,
        variationsVente: data.VenteVariations,
        expanded: false,
      };
      console.log(data.article.prix_vente_ht * sommeQte * 100,data.article.prix_vente_ht * sommeQte * data.remise / 100);
      this.ventes.push(vente);
      this.total += vente.montantHT;

      console.log(this.total);
      console.log(this.ventes);
    }

    this.updateValues();
  }
  deleteRow(rowIndex): void {
    const mont = this.ventes[rowIndex].montantHT;
    this.ventes.splice(rowIndex, 1);

    this.total = this.total - mont;
    this.htAmount =
      this.remisePercentage == 0.0
        ? this.total
        : (this.total * (100 - this.remisePercentage)) / 100;
    this.remiseAmount = (this.total * this.remisePercentage) / 100;
    //  this.totalFodec = (this.ttcAmount*101)/100;
    this.totalFodec = (this.htAmount * this.fodec) / 100;
    this.taxesAmount = (this.htAmount * this.tva) / 100 + this.totalFodec;
    this.timbreAmount = this.timbreAmount;
    this.ttcAmount = this.htAmount + this.taxesAmount;

    this.netToPayAmount = this.ttcAmount + this.timbreAmount;
    console.log(this.ventes);
  }
  roundValue(value: number): number {
    return Math.round(value * 100) / 100;
  }
  
   onDateChange(newDate: Date) {
    let previousDay = new Date(newDate);
    previousDay.setDate(newDate.getDate() + 1);
   this.postdate = previousDay ;
  }
  
  postdate
  
  onQteChange(achat, rowIndex, VenteVariation) {
    //   const foundVente=this.ventes[rowIndex]
    //   console.log("Modified VENTE",foundVente)
    //   const oldMontantHT=foundVente.montantHT
    //   for(let variations of foundVente.variationsVente){
    //     console.log("QTE",variations.QTE)

    //     foundVente.qte=0
    //   foundVente.qte +=variations.QTE
    //   foundVente.montantHT=0
    //   foundVente.montantHT=Math.round(foundVente.prixHT* foundVente.qte * 100)/ 100,
    //   console.log("montant HT",foundVente.montantHT)

    //   foundVente.montantTTC=0
    //   foundVente.montantTTC=Math.round(foundVente.prixTTC* foundVente.qte * 100)/ 100,
    //   console.log("montant TTC",foundVente.montantTTC)

    //   }
    //   this.total-=oldMontantHT
    //   this.total+=foundVente.montantHT
    //   this.htAmount=this.total
    // console.log("total",this.total)
    // console.log("VENTES",this.ventes[rowIndex])

    // this.updateValues();

    const foundVente = this.ventes[rowIndex];
    const oldMontantHT = foundVente.montantHT;

    console.log("Modified VENTE", foundVente);
    const oldqte = foundVente.qte;
    let somme = 0;
    for (let variations of foundVente.variationsVente) {
      console.log("QTE", variations.QTE);
      somme += variations.QTE;
    }
    console.log(somme);
    foundVente.qte = somme;
    foundVente.montantHT = 0;
    (foundVente.montantHT =
      Math.round(foundVente.prixHT * foundVente.qte * 100) / 100),
      console.log("montant HT", foundVente.montantHT);

    foundVente.montantTTC = 0;
    (foundVente.montantTTC =
      Math.round(foundVente.prixTTC * foundVente.qte * 100) / 100),
      console.log("montant TTC", foundVente.montantTTC);
    this.total -= oldMontantHT;
    this.total += foundVente.montantHT;
    this.htAmount = this.total;
    console.log("total", this.total);
    console.log("VENTES", this.ventes[rowIndex]);

    this.updateValues();
  }
  receiveConfirm(data) {
    console.log("response of confirmation", data);
    this.confirm = data;
    if (this.confirm == 1) {
      this.saveVente();
    } else {
    }
  }

  async AddVente() {
    console.log(this.date);

    switch (true) {
      case !this.selectedEtablissement:
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Sélectionnez Etablissement",
        });
        break;
      case !this.selectedStore:
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Sélectionnez le dépôt",
        });
        break;
      case !this.slectedClient:
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Sélectionnez Client",
        });
        break;
      case !this.date:
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Sélectionnez date",
        });
        break;
      // case !this.CodeFourniss:
      //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Taper le Code Facture' });
      //   break;
      case this.ventes.length === 0:
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Saisie une ticket",
        });
        break;
      default:
        await this.ConfirmDialog.showDialog();
    }
  }
  saveVente() {
    //         console.log(this.date)

    // switch (true) {

    //   case !this.selectedEtablissement:
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Etablissement' });
    //     break;
    //     case !this.selectedStore:
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez le dépôt' });
    //     break;
    //     case !this.slectedClient:
    //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Client' });
    //       break;
    //       case !this.date:
    //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez date' });
    //         break;
    //         // case !this.CodeFourniss:
    //         //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Taper le Code Facture' });
    //         //   break;
    //         case this.ventes.length===0:
    //           this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Saisie une ticket' });
    //           break;
    //   default:
    const currentDate = this.postdate ? this.postdate : new Date(this.date);
    const formattedDate = currentDate.toISOString();
    console.log(formattedDate);
   let tHt=0
    let tRe=0
    for (const vente of this.ventes) {
      if (!this.VenteProduct.includes(vente.article.id)) {
          
        this.VenteProduct.push(vente.article.id);
      }
    }
    console.log(this.VenteProduct);

    for (const vente of this.ventes) {
      for (const venteVar of vente.variationsVente) {
        // if (!this.VentesVariations.includes(vente.article.id)) {

        //   this.VenteProduct.push(vente.article.id)
        //   }
      const variation = {
          variation: venteVar.ID,
          quantity: venteVar.QTE,
          remise: vente.remise,
          prix_produit: vente.prixTTC,
          ht: vente.prixHT,
          htVente : vente.prixHTR,
          tva: this.tva,
        };
        
        tHt +=   vente.prixHTR*venteVar.QTE  
        tRe +=vente.remise > 0 ? vente.prixHTR*venteVar.QTE * (vente.remise / 100) : 0 
        
        this.VentesVariations.push(variation);
      }
    }
    console.log(tHt)
     console.log(tRe)
     const tHtRe = Math.round((tHt - Math.round(tRe * 1000) / 1000) * 1000) / 1000;
const tBaseHt = Math.round((tHtRe + this.totalFodec) * 1000) / 1000;
const tTaxe = Math.round(tBaseHt * 0.19 * 1000) / 1000;
const tTtc = Math.round((tBaseHt + tTaxe) * 1000) / 1000;
const tNet = Math.round((tTtc + this.timbreAmount) * 1000) / 1000;
     console.log(tHtRe,tBaseHt,tTaxe,tTtc,tNet)
    /* this.htAmount = this.remisePercentage == 0.0 ? this.total :  (this.total * (100-this.remisePercentage))/100 ;
    this.remiseAmount = (this.total * this.remisePercentage)/100;
    this.totalFodec = (this.htAmount*101)/100;
    this. taxesAmount = ((this.htAmount * this.tva)/100)+this.totalFodec ;
    this.timbreAmount = 0.6;
    this.ttcAmount = this.htAmount + this.taxesAmount ;
    this.netToPayAmount = this.ttcAmount + this.timbreAmount;*/
    const data = {
      products: this.VenteProduct,
      etablissement: this.selectedEtablissement.id,
      store: this.selectedStore.id,
      client: this.slectedClient.id,
      pourcentage_remise: this.remisePercentage,
      remise: this.remiseAmount,
      ht: tHtRe,
      taxes: tTaxe,
      ttc: tTtc,
      total_fodec: this.totalFodec,
      timbre: this.timbreAmount,
      prix_net: tNet,
      code_facture_client: this.CodeFourniss,
      date_vente: formattedDate,
      vente_variations: this.VentesVariations,
      status_vente: 2,
    };
    this.venteService.createTicket(data).then((res) => console.log(res));
    this.messageService.add({
      severity: "success",
      summary: "",
      detail: "Ticket créer avec succée",
    });
    console.log(data);
    this.selectedEtablissement = "";
    this.selectedStore = "";
    this.slectedClient = "";
    this.CodeFourniss = "";
    this.ventes = [];
    this.VenteProduct = [];
    this.VentesVariations = [];
    this.total = 0;
    this.htAmount = 0;
    this.remiseAmount = 0;
    this.taxesAmount = 0;
    this.timbreAmount = this.timbre;
    this.ttcAmount = 0;
    this.totalFodec = 0;
    this.netToPayAmount = this.ttcAmount + this.timbreAmount;
    //  this.netToPayAmount =0 ;
    this.remisePercentage = 0;
    //     break;
    // }
  }
  // }
}
